import React from "react";
import { Modal } from "react-bootstrap";

const ComplianceStep = (props) => {
  const handleProceed = () => {
    props.onProceed();
  };

  return (
    <>
      <Modal.Body>
        {props.question?.title && (
          <h4 className="compliance-title">{props.question.title} </h4>
        )}
        <p>{props.question?.question}</p>
      </Modal.Body>

      <Modal.Footer className="justify-content-between">
        <button onClick={props.onBack} className="outline">
          Go back
        </button>
        <button onClick={handleProceed} className="dark">
          Customer Consents
        </button>
      </Modal.Footer>
    </>
  );
};

export default ComplianceStep;
