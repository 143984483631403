import React from "react";

const TextInput = ({ onChange, onlyNumbers, onlyLetters, ...rest }) => {
  const handleChange = (e) => {
    if (onlyNumbers) {
      if (e.target.value !== "" && !e.target.value.match(/^[0-9]+$/)) {
        return false;
      }
    } else if (onlyLetters) {
      if (e.target.value !== "" && !e.target.value.match(/^[A-Za-z\-\s]+$/)) {
        return false;
      }
    }
    onChange(e);
  };

  return <input {...rest} onChange={handleChange} />;
};

export default TextInput;
