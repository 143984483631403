import React, { useEffect, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";

import { getBlankApplication } from "helpers/application";
import { getVrmSearch, updateApplicationVehicle } from "services";

const VehicleInformation = (props) => {
  const [loading, setLoading] = useState(false);
  const [mileage, setMileage] = useState(0);
  const [salesValue, setSalesValue] = useState(0);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [vehicle, setVehicle] = useState({});
  const [vehicleLookedUp, setVehicleLookedUp] = useState(false);

  useEffect(() => {
    if (props.application) {
      setSalesValue(props?.application?.vehicle?.salesValue ?? 0);
      setMileage(props?.application?.vehicle?.mileage ?? 0);

      if (props.application?.vehicle?.registrationNumber) {
        setVehicle(props?.application?.vehicle);
      }
    }
  }, [props.application]);

  const searchVehicle = () => {
    if (vehicle.registrationNumber) {
      getVrmSearch({
        pxVehicleVRM: vehicle?.registrationNumber.replace(" ", ""),
      }).then((res) => {
        setVehicle({
          registrationNumber: res.pxVehicleVRM,
          modelYear: res.modelYear,
          colour: res.colour,
          make: res.make,
          model: res.model,
          vin: res.vin,
          fuelType: res.fuelType,
          engineCapacity: res.engineCapacity,
        });
        setVehicleLookedUp(true);
      });
    }
  };

  const handleInputChange = (e) => {
    if (e.target.name === "registrationNumber") {
      setVehicleLookedUp(false);
    }

    setVehicle({
      ...vehicle,
      [e.target.name]: e.target.value,
    });
  };

  const updateApplication = () => {
    setLoading(true);

    if (props.application?.applicationGuid) {
      updateApplicationVehicle({
        applicationGuid: props.application?.applicationGuid,
        newVehicleVrm: vehicle?.registrationNumber.replace(" ", ""),
        newVehicleMileage: mileage,
        newVehicleSalesValue: salesValue,
      }).then((res) => {
        setLoading(false);
        setShowSuccessMsg(true);

        if (props.setApplication) {
          props.setApplication(res);
        }
      });
    } else {
      let newApplication = getBlankApplication(props.application);

      props.onUpdate({
        vehicle: {
          ...newApplication?.vehicle,
          ...vehicle,
          salesValue,
          mileage,
        },
      });

      props.setActiveTab("partexchange");

      setLoading(false);
    }
  };

  return (
    <div className="customer-information-form">
      <div className="customer-information-form-content">
        <div className="customer-details">
          <Row>
            <Col xs={6} md={7}>
              <label className="large mb-3 mb-md-0">
                Enter vehicle registration:
              </label>
              <input
                name="registrationNumber"
                value={vehicle?.registrationNumber ?? ""}
                onChange={handleInputChange}
                className="vehicle-reg-input ms-md-4"
                tabIndex={1}
              />
            </Col>
            <Col xs={6} md={5} className="d-flex align-items-end">
              <button
                className="pink focus"
                onClick={searchVehicle}
                tabIndex={2}
              >
                Lookup vehicle
              </button>
            </Col>
          </Row>

          <Row>
            <Col xs={4} md={2}>
              <label>Make</label>
              <div>{vehicle?.make}</div>
            </Col>
            <Col xs={4} md={3}>
              <label>Model</label>
              <div>{vehicle?.model}</div>
            </Col>
            <Col xs={4} md={2}>
              <label>Model Year</label>
              <div>{vehicle?.modelYear}</div>
            </Col>
            <Col xs={4} md={4} xxl={2}>
              <label>Colour</label>
              <div>{vehicle?.colour}</div>
            </Col>
            <Col xs={4} md={4} xxl={3}>
              <label>VIN</label>
              <div>{vehicle?.vin}</div>
            </Col>
          </Row>

          <Row className="form-container align-items-end">
            <Col xs={6} md={3}>
              <label>Sales price</label>
              <div className="form-item mb-0 mt-2">
                <input
                  type="text"
                  value={"£" + parseInt(salesValue).toLocaleString("en-GB")}
                  onChange={(e) =>
                    setSalesValue(
                      parseInt(
                        e.target.value.replace(",", "").replace("£", "") || 0
                      )
                    )
                  }
                  className="form-input text-end"
                  tabIndex={3}
                />
              </div>
            </Col>
            <Col xs={6} md={3}>
              <label>Mileage</label>
              <div className="form-item mb-0 mt-2">
                <input
                  type="text"
                  value={parseInt(mileage).toLocaleString("en-GB")}
                  onChange={(e) =>
                    setMileage(parseInt(e.target.value.replace(",", "") || 0))
                  }
                  className="form-input text-end"
                  tabIndex={4}
                />
              </div>
            </Col>
            {/*
            <Col xs={6} md={3}>
              <button
                className="dark px-4"
              >
                Adjust price
              </button>
            </Col>
            */}
          </Row>

          <Row className="align-items-end mb-0">
            <Col
              xs={12}
              className="d-flex justify-content-end align-items-center"
            >
              {showSuccessMsg && (
                <span className="text-success">Vehicle has been updated</span>
              )}

              <button
                disabled={loading || !vehicleLookedUp}
                onClick={updateApplication}
                className="dark ms-5 focus"
                tabIndex={5}
              >
                {loading ? (
                  <Spinner animation="border" variant="light" size="sm" />
                ) : (
                  <>Save vehicle & continue</>
                )}
              </button>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default VehicleInformation;
